jQuery(document).ready(function ($) {

    //zim shipping table show
    $('.opener').on('click', function () {
        $(this).siblings('.slide').slideToggle();
    });

  // tabs activate for calculator page
  $('#calcTabs').tabs({ active: 0 });

  // // lightbox for vin check page
  // if (typeof autoThemeSettings !== 'undefined') {
  //   let lightboxSettings = {
  //     imageLoading: autoThemeSettings.childThemeUrl + '/images/lightbox-ico-loading.gif',
  //     imageBtnPrev: autoThemeSettings.childThemeUrl + '/images/lightbox-btn-prev.gif',
  //     imageBtnNext: autoThemeSettings.childThemeUrl + '/images/lightbox-btn-next.gif',
  //     imageBtnClose: autoThemeSettings.childThemeUrl + '/images/lightbox-btn-close.gif',
  //     imageBlank: autoThemeSettings.childThemeUrl + '/images/lightbox-blank.gif'
  //   };
  //   $('.images_table a').lightBox(lightboxSettings);
  // }
  //
  //   // popVideo for vin check page
  //   $('#video').click(function () {
  //       $('#video').popVideo({
  //           playOnOpen:true
  //       }).open()
  //   });

    // gallery for my account page
    var $gallery = new SimpleLightbox('.gallery a', {
        captions: false
    });
});

